import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Icon } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Delete } from '@mui/icons-material';
import { parseISO } from 'date-fns';

import { useError } from '../../../contexts/ErrorContext';
import { GenericModal } from '../../common/GenericModal';
import { AccordionFL } from '../../AccordionFL';
import { TitleFL } from '../../TitleFL';
import { SubtitleFL } from '../../SubtitleFL';
import { getIconByCategory, getStatusStages, alertColor } from '../../../utils';
import { Categorias } from '../../common/Categorias';
import { CampainEditBriefingModal } from '../CampainEditBriefingModal';
import { CampainChat } from '../CampainChat';
import { FlagStatus } from '../../FlagStatus';
import { EditableDate } from '../../EditableDate';
import { EditableTitleCard } from '../../EditableTitleCard';
import { CampainWorkflowSteps } from '../CampainWorkflowSteps';
import { CampainWorkflowStepDetails } from '../CampainWorkflowStepDetails';
import {
    apiPostApprovedCampaign,
    apiPostReprovedCampaign,
    apiUpdateCampanhaItens,
    apiGetVideoInformations,
} from '../../../services';
import { CampanhaItensReprova } from '../../../pages/campanhas/CampanhaItensReprova';
import { showMessage, mensagemVazio } from '../../../data/Interfaces';
import { Loader } from '../../Loader';
import { DeletableItem } from '../../DeletableItem';
import { apiDeleteCampanhaItem } from '../../../services/apiDeleteCampanhaItem';

import { ButtonFL } from '../../ButtonFL';
import { TextEditor } from '../../../components/TextEditor';

export const CampainCardDetailsModal = ({
    item,
    open,
    children,
    onClose,
    updateCampanhaItem,
    handleOnEditCampanhaItem,
    setItemIdCampain,
    itemIdCampain,
    campanhaItens,
    setUpdated,
}) => {
    const [campain, setCampain] = useState(item);
    const [openEditWorkflowModal, setOpenEditWorkflowModal] = useState({ show: false, children: false, etapa: [] });
    const [openEditCategoryModal, setOpenEditCategoryModal] = useState(false);
    const [openEditBiefingModal, setOpenEditBiefingModal] = useState(false);
    const [openModalReprove, setOpenModalReprove] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isEditableDate, setIsEditableDate] = useState(false);
    const [titleCard, setTitleCard] = useState(campain?.titulo);
    const [completedDateCard, setCompletedDateCard] = useState(
        campain?.completedDate && parseISO(campain?.completedDate),
    );
    const [isLoading, setIsLoading] = useState(true);
    const [updatedInternal, setUpdatedInternal] = useState(true);
    const [message, setMessage] = useState(mensagemVazio);
    const [videoInfo, setVideoInfo] = useState({});

    const decodedBriefing = decodeURIComponent(item?.escopoBriefing || item?.campanhaBriefing || '');
    const [editorContent, setEditorContent] = useState(decodedBriefing);

    const icon = getIconByCategory(campain?.categoria);

    const { showError } = useError();

    const flagData = getStatusStages(item?.status);
    const description = flagData
        ? 'O creator enviou sua sugestão de roteiro ou idéia criativa para sua aprovação. Clique no ícone abaixo para visualizar e utilize os botões para aprovar ou reprovar.'
        : 'Todas as informações e arquivos enviados pelo creator para essa entrega aparecerão aqui.';

    const handleOpenEditCategorModal = () => {
        setOpenEditCategoryModal(true);
    };

    const setItem = async (updatedItem) => {
        setUpdatedInternal(true);
        setCampain(updatedItem);
        setOpenEditCategoryModal(false);
        const selectedIndex = campain?.id === updatedItem.id;
        updatedItem = { ...updatedItem, changeEscopoNome: true };
        handleOnEditCampanhaItem(selectedIndex);
        await updateCampanhaItem(updatedItem);
    };

    const handleOpenEditBriefingModal = () => {
        setOpenEditBiefingModal(!openEditBiefingModal);
    };

    const handleDeleteCampanhaItem = async (campanhaItem) => {
        setIsLoading(true);

        try {
            setOpenModalReprove(false);
            setItemIdCampain(null);

            await apiDeleteCampanhaItem(campanhaItem.id, campanhaItem);

            setUpdatedInternal((prevUpdatedInternal) => !prevUpdatedInternal);
            setUpdated((prevUpdated) => !prevUpdated);

            onClose();

            showMessage({ variant: alertColor.warning, message: 'Campanha Item deletado com sucesso!' }, setMessage);
        } catch (error) {
            showError(error);
        }
    };

    const handleSave = async () => {
        const updatedItem = {
            ...item,
            escopoBriefing: editorContent,
        };

        await onSave(updatedItem);
    };

    const onSave = async (item) => {
        setIsLoading(true);

        try {
            setItemIdCampain(item?.id);
            await apiUpdateCampanhaItens(item.id, item);

            setUpdatedInternal((prevUpdated) => !prevUpdated);
            setUpdated((prevUpdated) => !prevUpdated);
            setOpenEditBiefingModal(false);
        } catch (error) {
            showError(error);
        }
    };

    const handleModal = async ({ etapa, children = false }) => {
        if (etapa?.dados?.length) {
            try {
                setIsLoading(true);

                if (etapa?.dados[0]?.nome == 'videoFile') {
                    const data = await apiGetVideoInformations(item?.idVideo);
                    const videoData = {
                        ...data,
                        videoSubtitle: campain?.videoFileTexto,
                    };
                    setVideoInfo(videoData);
                }

                setOpenEditWorkflowModal({ etapa: etapa, children: children, show: true });
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleModalClose = async () => {
        setOpenEditWorkflowModal({ etapa: [], children: children, show: false });
        setOpenModalReprove(false);
    };

    const handleOnAproveCampanhaItem = async (id) => {
        setIsLoading(true);

        try {
            setItemIdCampain(id);
            await apiPostApprovedCampaign(id);
            setUpdatedInternal((prevUpdatedInternal) => !prevUpdatedInternal);
            setUpdated((prevUpdated) => !prevUpdated);
            showMessage({ variant: alertColor.success, message: 'Aprovação realizada com sucesso' }, setMessage);
        } catch (error) {
            showError(error);
        }
    };

    const handleOnReproveCampanhaItem = async (id, reprovedMessage) => {
        setIsLoading(true);

        try {
            setOpenModalReprove(false);
            setItemIdCampain(id);
            await apiPostReprovedCampaign(id, reprovedMessage);
            setUpdatedInternal((prevUpdatedInternal) => !prevUpdatedInternal);
            setUpdated((prevUpdated) => !prevUpdated);
            showMessage({ variant: alertColor.warning, message: 'Reprovação realizada com sucesso' }, setMessage);
        } catch (error) {
            showError(error);
        }
    };

    const handleOpenItemReproveModal = () => {
        setOpenModalReprove(true);
    };

    const handleTextClick = () => {
        setIsEditable(true);
    };

    const onSaveTitle = async () => {
        if (!item) {
            return;
        }

        const itemUpdated = { ...item, titulo: titleCard };

        setIsEditable(false);

        await onSave(itemUpdated);
    };

    const handleTextChange = (event) => {
        setTitleCard(event.target.value);
    };

    const handleDateClick = () => {
        setIsEditableDate(true);
    };

    const handleDateChange = (date) => {
        setCompletedDateCard(date);
    };

    const onSaveDate = async () => {
        if (!item) {
            return;
        }

        const itemUpdated = { ...item, completedDate: completedDateCard?.toISOString() };

        setIsEditableDate(false);

        await onSave(itemUpdated);
    };

    useEffect(() => {
        const itemUpdated = campanhaItens?.find((item) => item.id === itemIdCampain);
        setCampain(itemUpdated);
        setIsLoading(false);
    }, [campanhaItens, updatedInternal]);

    useEffect(() => {
        setCampain(item);

        setIsLoading(false);
    }, [item?.id]);

    return (
        <>
            {isLoading && <Loader />}

            <GenericModal open={open} onClose={onClose}>
                <Box sx={{ px: 3, py: 5 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            bgcolor: 'var(--grey-300-color)',
                            borderRadius: 2,
                            mb: 4,
                            mt: 3,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: 0.5,
                            }}
                        >
                            <Typography variant="body1" marginLeft={1}>
                                {item?.creatorName}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            '@media (max-width: 600px)': {
                                flexDirection: 'column',
                            },
                        }}
                    >
                        <Box sx={{ width: '50%', '@media (max-width: 600px)': { width: '100%' } }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <TitleFL title={campain?.subcategoria} titleSize="h4" />
                                    <IconButton onClick={handleOpenEditCategorModal} aria-label="editModal">
                                        <EditOutlinedIcon fontSize="small" sx={{ marginLeft: 1 }} />
                                    </IconButton>
                                </Box>
                            </Box>
                            <SubtitleFL subtitle={campain?.categoria} iconSubtitle={icon} />
                            <EditableTitleCard
                                titleCard={titleCard}
                                isEditable={isEditable}
                                handleTextClick={handleTextClick}
                                handleTextChange={handleTextChange}
                                onSaveTitle={onSaveTitle}
                                setIsEditable={setIsEditable}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: '50%',
                                display: 'flex',
                                gap: 1,
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                '@media (max-width: 600px)': {
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                },
                            }}
                        >
                            <EditableDate
                                isEditableDate={isEditableDate}
                                completedDateCard={completedDateCard}
                                handleDateClick={handleDateClick}
                                handleDateChange={handleDateChange}
                                onSaveDate={onSaveDate}
                            />

                            <DeletableItem item={item} handleDeleteCampanhaItem={handleDeleteCampanhaItem} />
                        </Box>
                    </Box>

                    <GenericModal onClose={onClose} open={openEditCategoryModal}>
                        <Box sx={{ padding: 3 }}>
                            <Typography variant="h5" sx={{ marginBottom: 3 }}>
                                Selecione o tipo da entrega
                            </Typography>
                            <Categorias item={campain} setItem={setItem} />
                        </Box>
                    </GenericModal>
                    <Box sx={{ my: 2 }}>
                        <AccordionFL
                            title="Briefing"
                            description={decodedBriefing}
                            icon={<DescriptionOutlinedIcon />}
                            handleOpenEditBriefingModal={handleOpenEditBriefingModal}
                        >
                            <Box sx={{ pt: 1, pb: 2, px: 2 }}>
                                <TextEditor setEditorContent={setEditorContent} editorContent={editorContent} />
                                <ButtonFL textKey="Salvar" onClick={handleSave} fullWidth />
                            </Box>
                        </AccordionFL>
                    </Box>
                    <CampainEditBriefingModal
                        open={openEditBiefingModal}
                        onClose={handleOpenEditBriefingModal}
                        item={campain}
                        onSave={onSave}
                    />
                    <Box
                        sx={{
                            bgcolor: 'var(--primary-color)',
                            p: 2,
                            paddingBottom: 4,
                            borderRadius: 2,
                            boxSizing: 'border-box',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: 2,
                                color: 'var(--white-color)',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <TitleFL title="Histórico de Entregas" iconTitle={<ScheduleSendOutlinedIcon />} />
                            </Box>
                            <Box>{flagData && <FlagStatus color={flagData.color} text={flagData.text} />}</Box>
                        </Box>
                        <Box sx={{ color: 'white', marginBottom: 5 }}>
                            <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                                {description}
                            </Typography>
                        </Box>

                        <CampainWorkflowSteps item={campain} handleModal={handleModal} />
                    </Box>
                    <CampainChat item={campain} />
                </Box>
            </GenericModal>
            {openEditWorkflowModal.show && (
                <CampainWorkflowStepDetails
                    open={openEditWorkflowModal.show}
                    item={campain}
                    onClose={handleModalClose}
                    etapa={openEditWorkflowModal.etapa}
                    handleOnAproveCampanhaItem={handleOnAproveCampanhaItem}
                    handleOpenItemReproveModal={handleOpenItemReproveModal}
                    message={message}
                    isLoading={isLoading}
                    videoInfo={videoInfo}
                />
            )}

            {openModalReprove && (
                <CampanhaItensReprova
                    item={campain}
                    onClose={handleModalClose}
                    handleOnReproveCampanhaItem={handleOnReproveCampanhaItem}
                />
            )}
        </>
    );
};
