import { Box, Modal, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useMobileDetect } from '../../hooks';

export const GenericModal = ({ title, children, open, onClose, goBack, bgColor }) => {
    const isMobile = useMobileDetect();

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: isMobile ? '95%' : '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: bgColor ?? 'var(--grey-100-color)',
        color: 'var(--primary-dark-color)',
        borderRadius: 2,
        maxHeight: 'calc(100vh - 40px)',
        overflowY: 'auto',
        msOverflowStyle: 'none',
    };

    const isOpen = typeof open == 'object' ? open.isOpen : open;

    return (
        <Modal open={Boolean(isOpen)} onClose={onClose} sx={{ borderRadius: 3 }}>
            <Box sx={modalStyle}>
                {onClose && (
                    <IconButton
                        onClick={onClose}
                        sx={{ marginRight: 1, marginTop: 1, float: 'right', color: 'var(--primary-dark-color)' }}
                    >
                        <Close />
                    </IconButton>
                )}
                {title && (
                    <Typography variant="h5" align="center">
                        {title}
                    </Typography>
                )}
                <Box>{children}</Box>
            </Box>
        </Modal>
    );
};
