import { ArrowDownward, ArrowUpward, Delete, ModeEdit } from '@mui/icons-material';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Get } from '../../data/Verbs';
import { useError } from '../../contexts/ErrorContext';

export const CampanhasList = ({ campanhas, handleOrdenar, ordenacao }) => {
    const { showError } = useError();
    const [workflow, setWorkflow] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchWorkflow = async () => {
            try {
                const data = await Get(`${process.env.REACT_APP_API_URL}/workflows?sort=nome_asc`);
                setWorkflow(data);
            } catch (error) {
                showError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchWorkflow();
    }, []);

    const navigate = useNavigate();

    const editarCampanha = (campanha) => {
        navigate(`/campanhas/editar/${campanha.id}`);
    };

    const isOrdenadoAsc = (campo) => {
        return ordenacao === `${campo}_asc`;
    };

    const isOrdenadoDesc = (campo) => {
        return ordenacao === `${campo}_desc`;
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#424242',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const iconStyle = {
        fontSize: '14px',
    };

    return !isLoading ? (
        workflow?.length > 0 && (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell
                                onClick={() => handleOrdenar('titulo')}
                                style={{
                                    cursor: 'pointer',
                                    fontWeight: isOrdenadoAsc('titulo') || isOrdenadoDesc('titulo') ? 'bold' : 'normal',
                                }}
                            >
                                Título
                                {isOrdenadoAsc('titulo') && <ArrowUpward style={iconStyle} />}
                                {isOrdenadoDesc('titulo') && <ArrowDownward style={iconStyle} />}
                            </StyledTableCell>
                            <StyledTableCell
                                onClick={() => handleOrdenar('cliente')}
                                style={{
                                    cursor: 'pointer',
                                    fontWeight:
                                        isOrdenadoAsc('cliente') || isOrdenadoDesc('cliente') ? 'bold' : 'normal',
                                }}
                            >
                                Cliente
                                {isOrdenadoAsc('cliente') && <ArrowUpward style={iconStyle} />}
                                {isOrdenadoDesc('cliente') && <ArrowDownward style={iconStyle} />}
                            </StyledTableCell>
                            <StyledTableCell
                                onClick={() => handleOrdenar('workflow')}
                                style={{
                                    cursor: 'pointer',
                                    fontWeight:
                                        isOrdenadoAsc('workflow') || isOrdenadoDesc('workflow') ? 'bold' : 'normal',
                                }}
                            >
                                Workflow
                                {isOrdenadoAsc('workflow') && <ArrowUpward style={iconStyle} />}
                                {isOrdenadoDesc('workflow') && <ArrowDownward style={iconStyle} />}
                            </StyledTableCell>
                            <StyledTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {campanhas.map((campanha) => {
                            const workflowName = workflow.find((wf) => wf.id === campanha.workflow)?.nome || 'N/A';
                            return (
                                <TableRow
                                    key={campanha.id}
                                    className="nowrap"
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                    onClick={() => editarCampanha(campanha)}
                                >
                                    <TableCell component="th" scope="row">
                                        {campanha.titulo}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {campanha.clienteNome}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {workflowName}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button
                                            aria-label="Editar Campanha"
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Evita que o click navegue para outra página
                                                editarCampanha(campanha);
                                            }}
                                            sx={{ color: 'var(--primary-color)' }}
                                        >
                                            <ModeEdit />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    ) : (
        <p>Carregando...</p>
    );
};
