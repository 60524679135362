import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import ForumIcon from '@mui/icons-material/Forum';
import { TitleFL } from '../../TitleFL';
import { Chats } from '../../chat/Chats';
import { storageCliente, storageContexto } from '../../../utils/handleStorage.utils';

export const CampainChat = ({ item }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        const isCliente = storageContexto === 'ADMIN_CLIENTE_CAMPANHAITEM' && storageCliente;
        if (isCliente) {
            setSelectedTab(1);
        }
    }, []);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box sx={{ bgcolor: 'var(--white-color)', borderRadius: 2, p: 2, marginTop: 2 }}>
            <TitleFL title="Conversar com:" iconTitle={<ForumIcon />} />

            <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="tabs">
                <Tab label="CREATOR" />
                <Tab label="CLIENTE" />
            </Tabs>

            {selectedTab === 0 && (
                <Chats item={item} contexto="ADMIN_CREATOR_CAMPANHAITEM" iconTitle={<HistoryIcon />} />
            )}

            {selectedTab === 1 && (
                <Chats item={item} contexto="ADMIN_CLIENTE_CAMPANHAITEM" iconTitle={<HistoryIcon />} />
            )}
        </Box>
    );
};
