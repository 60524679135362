import { useEffect, useState } from 'react';

import { People } from '@mui/icons-material';
import { Avatar, Box, Button, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import InputMask from 'react-input-mask';
import { accessLevels } from '../../data/Interfaces';
import { Get } from '../../data/Verbs';
import { useAuthStore } from '../../store';
import { ButtonFL } from '../../components/ButtonFL';
import { useError } from '../../contexts/ErrorContext';

export const Usuario = ({ usuario, handleFechar, handleSalvar }) => {
    const [clientes, setClientes] = useState([]);
    const [form, setForm] = useState({
        nome: usuario.nome || '',
        email: usuario.email || '',
        telefone: usuario.telefone || '',
        acesso: usuario.acesso || '',
        senha: usuario.senha || '',
        cliente: usuario.cliente || null,
    });
    const { showError } = useError();
    const user = useAuthStore((state) => state.user);
    const updateUser = useAuthStore((state) => state.updateUser);

    const handleSubmit = (event) => {
        event.preventDefault();

        const updated = {
            ...usuario,
            nome: form.nome,
            email: form.email,
            telefone: form.telefone,
            acesso: form.acesso,
            cliente: form.cliente,
            clienteNome: clientes.find((cliente) => cliente.id === form.cliente)?.nome || '',
        };

        if (usuario.id === '0') {
            updated.senha = form.senha;
        }

        if (usuario.id && usuario.id == user.id) updateUser(updated);

        handleSalvar(updated);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Get(`${process.env.REACT_APP_API_URL}/clientes?sort=nome_asc`);
                setClientes(data);
            } catch (error) {
                showError(error);
            }
        };

        fetchData();
    }, []);

    return (
        <Box
            sx={{
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'var(--white-color)',
            }}
        >
            <Avatar
                sx={{
                    m: 1,
                    mt: 3,
                    bgcolor: 'var(--primary-color)',
                }}
            >
                <People />
            </Avatar>

            <Typography component="h1" variant="h5">
                {usuario.id === '0' ? 'Adicionar Usuário' : 'Alterar Usuário'}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ boxSizing: 'border-box', width: '100%', p: 4 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="nome"
                    label="Nome do Usuário"
                    id="nome"
                    value={form.nome}
                    onChange={handleInputChange}
                    autoComplete="Nome do Usuário"
                />
                <TextField
                    margin="normal"
                    type="email"
                    required
                    fullWidth
                    name="email"
                    label="E-mail"
                    id="email"
                    value={form.email}
                    onChange={handleInputChange}
                    autoComplete="E-mail"
                />
                <InputMask
                    mask="(99) 99999-9999"
                    required
                    fullWidth
                    name="telefone"
                    label="Telefone"
                    id="telefone"
                    autoComplete="Telefone"
                    margin="normal"
                    value={form.telefone}
                    onChange={handleInputChange}
                >
                    {(inputProps) => <TextField {...inputProps} />}
                </InputMask>

                {usuario.id === '0' && (
                    <TextField
                        margin="normal"
                        type="password"
                        required
                        fullWidth
                        name="senha"
                        label="Senha"
                        id="senha"
                        value={form.senha}
                        onChange={handleInputChange}
                        autoComplete="Senha"
                    />
                )}

                {user.acesso === 1 && (
                    <>
                        <InputLabel id="acessoLabel">Nível de Acesso</InputLabel>
                        <Select
                            labelId="acessoLabel"
                            name="acesso"
                            id="acesso"
                            value={form.acesso}
                            onChange={handleInputChange}
                            label="Acesso"
                            fullWidth
                            required
                        >
                            {accessLevels.map((level) => (
                                <MenuItem key={level.value} value={level.value}>
                                    {level.label}
                                </MenuItem>
                            ))}
                        </Select>

                        {form.acesso === 3 && (
                            <>
                                <InputLabel htmlFor="cliente">Cliente</InputLabel>
                                {clientes.length > 0 ? (
                                    <>
                                        <Select
                                            name="cliente"
                                            id="cliente"
                                            label="Cliente"
                                            value={form.cliente}
                                            onChange={handleInputChange}
                                            required
                                            fullWidth
                                        >
                                            {clientes.map((cliente) => (
                                                <MenuItem key={cliente.id} value={cliente.id}>
                                                    {cliente.nome}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </>
                                ) : (
                                    <div>Carregando clientes...</div>
                                )}
                            </>
                        )}
                    </>
                )}

                <ButtonFL type="submit" fullWidth textKey="Salvar" />
                <ButtonFL fullWidth textKey="Voltar" onClick={() => handleFechar(false)} />
            </Box>
        </Box>
    );
};
