import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNotificationStore } from '../../store';
import { generateLink } from '../../utils';
import { apiGetCampanha } from '../../services/apiGetCampanha';
import { apiGetCampanhaItem } from '../../services/apiGetCampanhaItem';
import { isValidStorageValue, setSessionStorage } from '../../utils/handleStorage.utils';
import { useError } from '../../contexts/ErrorContext';
import CustomBadge from '../common/CustomBadge';

export const NotificationMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { notifications } = useNotificationStore((state) => ({
        notifications: state.notifications,
    }));

    const [links, setLinks] = useState([]);

    const { showError } = useError();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                const newLinks = await Promise.all(
                    notifications.map(async (notification) => {
                        if (
                            !isValidStorageValue(notification.idcampanha) &&
                            isValidStorageValue(notification.idcampanhaitem)
                        ) {
                            const campanhaItens = await apiGetCampanhaItem(notification.idcampanhaitem);
                            if (campanhaItens && campanhaItens.length > 0) {
                                notification.idcampanha = campanhaItens[0].campanha;
                                // notification.idcreator = campanhaItens[0].creator;
                            }
                        }

                        if (notification.idcampanha) {
                            const campanhaData = await apiGetCampanha(notification.idcampanha);
                            notification.campanhaTitulo = campanhaData?.titulo;
                            notification.idclienteusuario = campanhaData?.cliente;
                            notification.clienteNome = campanhaData?.clienteNome;
                        }

                        const link = await generateLink({
                            contexto: notification.contexto,
                            idcampanha: notification.idcampanha,
                            campanhaTitulo: notification.campanhaTitulo,
                            idcampanhaitem: notification.idcampanhaitem,
                            idcreator: notification.idcreator,
                            idusuario: notification.idusuario,
                            idclienteusuario: notification.idclienteusuario,
                        });

                        return { ...notification, link };
                    }),
                );
                setLinks(newLinks);
            } catch (error) {
                showError(error);
            }
        };

        if (notifications.length > 0) {
            fetchLinks();
        }
    }, [notifications]);

    const handleLinkClick = (notification) => {
        setSessionStorage({
            contextoAbrir: notification.contexto,
            idcampanha: notification.idcampanha,
            idcampanhaitem: notification.idcampanhaitem,
            idcreator: notification.idcreator,
            idusuario: notification.idusuario,
            idclienteusuario: notification.idclienteusuario,
        });
    };

    return (
        <div>
            <CustomBadge
                badgeContent={notifications.length}
                Icon={NotificationsIcon}
                buttonProps={{ color: 'inherit', onClick: handleClick }}
            />

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {links.length > 0 ? (
                    links.map((notification, index) => {
                        let sender = '';
                        let senderName = '';

                        if (notification.idusuario) {
                            sender = 'FLCO';
                            senderName = notification?.usuario?.nome;
                        } else if (notification.idcreator) {
                            sender = 'Creator';
                            senderName = notification?.creator?.nome;
                        } else if (notification.idclienteusuario) {
                            sender = 'Cliente';
                            senderName = notification?.clienteusuario?.nome;
                        }
                        const campanhaitemTitulo = notification?.campanhaitem?.titulo
                            ? `- ${notification.campanhaitem.titulo}`
                            : '- Sem título';

                        return (
                            <MenuItem key={index} onClick={handleClose}>
                                <a
                                    href={notification.link}
                                    onClick={() => handleLinkClick(notification)}
                                    style={{ textDecoration: 'none', color: 'var(--black-color)', size: '14px' }}
                                >
                                    <small>
                                        Chat de {sender} <strong>({senderName})</strong>
                                        &nbsp; em {notification?.clienteNome}
                                        &nbsp;- {notification?.campanhaTitulo}
                                        &nbsp;
                                        {notification?.campanhaitem ? campanhaitemTitulo : ''}
                                    </small>
                                </a>
                            </MenuItem>
                        );
                    })
                ) : (
                    <MenuItem onClick={handleClose}>Sem notificações</MenuItem>
                )}
            </Menu>
        </div>
    );
};
